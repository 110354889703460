/* tailwind.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Variabili per la Modalità Chiara */
:root {
  --foreground-color: #333333;
  --background-start-color: #ffffff;
  --background-end-color: #f9f9f9;
  --footer-bg-start: #f0f0f0;
  --footer-bg-end: #e0e0e0;
  --footer-text: #555555;
  --accent-color: #0066cc;
  --star-color: #cccccc; /* Colore delle stelle in modalità chiara */
}

/* Variabili per la Modalità Scura */
.dark {
  --foreground-color: #f0f0f0;
  --background-start-color: #1a1a1a;
  --background-end-color: #1a1a1a;
  --footer-bg-start: #333333;
  --footer-bg-end: #000000;
  --footer-text: #cccccc;
  --accent-color: #ffcc00;
  --star-color: #ffffff; /* Colore delle stelle in modalità scura */
}

body {
  color: var(--foreground-color);
  background: linear-gradient(
    to bottom,
    var(--background-start-color),
    var(--background-end-color)
  );
  position: relative;
  overflow-x: hidden; /* Previene lo scrolling orizzontale indesiderato */
  z-index: 1;
}

/* Animazione Twinkle */
@keyframes twinkle {
  0% {
    opacity: 0.7;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.7;
  }
}

/* Stili per le stelle */
.star {
  position: absolute;
  border-radius: 50%;
  background-color: var(--star-color);
  animation: twinkle 2s infinite alternate;
}

/* Nascondi le stelle su dispositivi mobili */
@media (max-width: 768px) {
  .star {
    display: none;
  }
}

/* Altri stili personalizzati */
/* ... Aggiungi qui altri stili se necessario ... */
