/* globals.css */

/* Importazioni Tailwind CSS */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Variabili per la Modalità Chiara (Default) */
:root {
  --foreground-color: #333333;
  --background-start-color: #ffffff;
  --background-end-color: #f9f9f9;
  --footer-bg-start: #f0f0f0;
  --footer-bg-end: #e0e0e0;
  --footer-text: #555555;
  --accent-color: #0066cc;
  --star-color: #cccccc; /* Colore delle stelle in modalità chiara */
}

/* Variabili per la Modalità Scura */
.dark {
  --foreground-color: #f0f0f0;
  --background-start-color: #1a1a1a;
  --background-end-color: #1a1a1a;
  --footer-bg-start: #333333;
  --footer-bg-end: #000000;
  --footer-text: #cccccc;
  --accent-color: #ffcc00;
  --star-color: #ffffff; /* Colore delle stelle in modalità scura */
}

/* Stili per il corpo della pagina */
body {
  color: var(--foreground-color);
  background: linear-gradient(
    to bottom,
    var(--background-start-color),
    var(--background-end-color)
  );
  position: relative;
  overflow-x: hidden; /* Previene lo scrolling orizzontale indesiderato */
  z-index: 1;
}

/* Animazione Twinkle per le stelle */
@keyframes twinkle {
  0% {
    opacity: 0.7;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.7;
  }
}

/* Stili per le stelle */
.star {
  position: absolute;
  border-radius: 50%;
  background-color: var(--star-color);
  animation: twinkle 2s infinite alternate;
}

/* Nascondi le stelle su dispositivi mobili */
@media (max-width: 768px) {
  .star {
    display: none;
  }
}

/* Stili per il Footer */
.footer {
  background: linear-gradient(
    to bottom right,
    var(--footer-bg-start),
    var(--footer-bg-end)
  );
  color: var(--footer-text);
}

.footer a {
  color: var(--footer-text);
  transition: color 0.3s ease;
}

.footer a:hover {
  color: var(--accent-color);
}

.footer-title {
  color: var(--accent-color);
}

.footer-input {
  background-color: rgba(255, 255, 255, 0.1);
  color: var(--footer-text);
  border: 1px solid var(--footer-text);
}

.footer-button {
  background-color: var(--accent-color);
  color: white;
}

.footer-button:hover {
  background-color: color-mix(in srgb, var(--accent-color) 80%, black);
}

/* Transizione per il Cambio di Tema */
.theme-transition {
  transition: background-color 0.3s ease, color 0.3s ease;
}

/* Effetto Ombra sul Testo */
.text-shadow {
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
}

.dark .text-shadow {
  text-shadow: 2px 2px 4px rgba(255, 255, 255, 0.1);
}

/* Altri stili personalizzati */
/* ... Aggiungi qui altri stili se necessario ... */
